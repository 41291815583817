import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import SettingsIcon from '../../assets/images/icons/settings.svg';
import Order from '../../components/Order';
import ErrorFlash from '../../components/Order/flash/ErrorFlash';
import LoadingIcon from '../../components/loading-icon';
import { getCustomers } from '../../lib/customer';
import { Customer } from '../../lib/customer/types';
import { createOrder } from '../../lib/order';
import { Order as OrderType } from '../../lib/order/types';
import { RootState } from '../../redux';
import { getCategory } from '../../redux/categories/actions';
import { endFixOrder } from '../../redux/fix-order/actions';
import { clearOrder } from '../../redux/orders/actions';
import { api } from '../../utils/api';

const Category = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const category = useSelector((state: RootState) => state.categories.categories[parseInt(id as string, 10)]);
    const oidcUser = useSelector((state: RootState) => state.oidc.user);
    const loadedOrder = useSelector((state: RootState) => state.orders.order);
    const { id: fixingOrderId, info: fixingOrderInfo } = useSelector((state: RootState) => state.fixOrder);

    const [customers, setCustomers] = useState<Customer[] | null>();
    const [order, setOrder] = useState<OrderType | undefined>(loadedOrder);
    const [error, setError] = useState({ error: '', show: false });

    const [hidePrice, setHidePrice] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getCategory(parseInt(id as string, 10)));

        return () => {
            dispatch(clearOrder());
            dispatch(endFixOrder());
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (!oidcUser?.access_token || !category?.id) return;
        getCustomers(oidcUser.access_token, category.id, undefined, 1).then((customers) => setCustomers(customers));
    }, [oidcUser?.access_token, category?.id]);

    useEffect(() => {
        if (!category?.id || !customers || customers.length === 0) return;
        if (order?.id) {
            document.title = `Tilausportaali - Kategoria: ${category.name} - Tilaus: ${order.id}`;
            return;
        }

        document.title = `Tilausportaali - Kategoria: ${category.name}`;

        createOrder(oidcUser?.access_token ?? '', { category: category.id, customer: customers[0].id, reference: '' })
            .then((order) => setOrder(order))
            .catch((err) => {
                console.error(err);
                showError(
                    'Tilauksen alustaminen epäonnistui. Yritä ladata sivu uudelleen. Mikäli ongelma jatkuu, ' +
                        'olethan yhteydessä ylläpitoon.',
                );
            });
    }, [category?.id, category?.name, order?.id, oidcUser?.access_token, customers]);

    useEffect(() => {
        const deleteEmptyOrder = () => order?.id && api.keepAlive('DELETE', `/order/${order.id}/empty`, {});

        window.addEventListener('beforeunload', deleteEmptyOrder);
        return () => {
            deleteEmptyOrder();
            window.removeEventListener('beforeunload', deleteEmptyOrder);
        };
    }, [order?.id]);

    const showError = (error: string) => setError({ error, show: true });
    const hideError = () => setError(({ error }) => ({ error, show: false }));

    const setPriceHidden = () => setHidePrice(!hidePrice);

    const [open, setOpen] = useState<boolean>(false);
    const toggleMenuOpen = () => setOpen((open) => !open);

    return (
        <div className='main-wrapper'>
            {category ? (
                <>
                    <div className='category-title-row'>
                        <h1>{category.name}</h1>
                    </div>

                    <div className='category-settings'>
                        <button
                            onClick={toggleMenuOpen}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <img src={SettingsIcon} />
                        </button>
                        {open && (
                            <div className='menu'>
                                <button onClick={setPriceHidden}>
                                    {!hidePrice ? 'Piilota nettohinta' : 'Näytä nettohinta'}
                                </button>
                            </div>
                        )}
                    </div>

                    {fixingOrderId && (
                        <div className='order__fix-order-info'>
                            <div className='order__fix-order-info-title'>Korjattavan tilauksen tiedot:</div>
                            <div>
                                <span className='order__fix-order-info-label'>Aika</span>
                                {new Date(fixingOrderInfo?.time ?? 0).toLocaleString()}
                            </div>
                            <div>
                                <span className='order__fix-order-info-label'>Tila</span>
                                {fixingOrderInfo?.status ?? ''}
                            </div>
                        </div>
                    )}

                    {customers && customers.length === 0 ? (
                        <span>
                            Sinulle ei ole kytketty asiakkuutta, joka tarvitaan tilauksen tekemiseen. Olet hyvä ja ota
                            yhteyttä ylläpitoon.
                        </span>
                    ) : order ? (
                        <Order
                            category={category}
                            disableExcel={fixingOrderId !== undefined}
                            enablePriceEdit={fixingOrderInfo !== undefined}
                            errorVisible={error.show}
                            hideError={hideError}
                            order={order}
                            showError={showError}
                            hidePrice={hidePrice}
                        />
                    ) : (
                        <LoadingIcon />
                    )}
                </>
            ) : (
                <LoadingIcon />
            )}

            <ErrorFlash {...error} />
        </div>
    );
};

export default Category;
