import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { api } from '../../utils/api';
import { initConfigFail, initConfigSuccess } from './actions';
import { ConfigActionTypes, INIT_CONFIG, INIT_CONFIG_SUCCESS, InitConfigAction } from './types';
import { ConfigState } from './types';

const initialState: ConfigState = {
    config: {
        logo: null,
    },
};

const config = (state = initialState, action: ConfigActionTypes): ConfigState => {
    switch (action.type) {
        case INIT_CONFIG_SUCCESS:
            return _.assign({}, state, { config: action.payload.res });
        default:
            return state;
    }
};

export const initConfigEpic = (action$: Observable<InitConfigAction>): Observable<ConfigActionTypes> =>
    action$.pipe(
        ofType(INIT_CONFIG),
        mergeMap(() =>
            api.get('/config').pipe(
                map((res) => initConfigSuccess(res.response)),
                catchError((error) => of(initConfigFail(error))),
            ),
        ),
    );

export default config;
