import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

import { Order } from '../../lib/order/types';

// State

export interface OldOrdersState {
    orders: Order[];
    contentRange: number;
    pageSize: number;
    loading: boolean;
}

// Action types

export const FETCH_OLD_ORDERS = 'old-orders:FETCH_OLD_ORDERS';
export const FETCH_OLD_ORDERS_SUCCESS = 'old-orders:FETCH_OLD_ORDERS_SUCCESS';
export const FETCH_OLD_ORDERS_FAIL = 'old-orders:FETCH_OLD_ORDERS_FAIL';

// Actions

export interface FetchOldOrdersAction extends Action {
    type: typeof FETCH_OLD_ORDERS;
    payload: {
        page: number;
        filter: Record<string, string>;
    };
}

export interface FetchOldOrdersSuccessAction extends Action {
    type: typeof FETCH_OLD_ORDERS_SUCCESS;
    payload: {
        orders: Order[];
        contentRange: number;
    };
}

export interface FetchOldOrdersFailAction extends Action {
    type: typeof FETCH_OLD_ORDERS_FAIL;
    error: AjaxError;
}

export type OldOrdersActionTypes = FetchOldOrdersAction | FetchOldOrdersSuccessAction | FetchOldOrdersFailAction;
