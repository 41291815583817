import { Link } from 'react-router-dom';

interface Props {
    id: number;
    name: string;
    imgUrl: string | null;
}

const CategoryLink = ({ id, name, imgUrl }: Props) => (
    <li className='category-link__wrapper'>
        <Link className='category-link' to={`kategoria/${id}`}>
            {imgUrl && <img className='category-link__img' src={imgUrl} alt={name} />}
            <div className='category-link__title'>{name}</div>
        </Link>
    </li>
);

export default CategoryLink;
