import _ from 'lodash';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { RootState } from '..';
import { api } from '../../utils/api';
import { fetchOldOrdersFail, fetchOldOrdersSuccess } from './actions';
import {
    FETCH_OLD_ORDERS,
    FETCH_OLD_ORDERS_SUCCESS,
    FetchOldOrdersAction,
    OldOrdersActionTypes,
    OldOrdersState,
} from './types';

const initialState: OldOrdersState = {
    orders: [],
    contentRange: 1,
    pageSize: 10,
    loading: false,
};

const oldOrders = (state = initialState, action: OldOrdersActionTypes): OldOrdersState => {
    switch (action.type) {
        case FETCH_OLD_ORDERS:
            return _.assign({}, state, { loading: true });
        case FETCH_OLD_ORDERS_SUCCESS:
            return _.assign({}, state, {
                orders: action.payload.orders,
                contentRange: action.payload.contentRange,
                loading: false,
            });
        default:
            return state;
    }
};

export const fetchOldOrdersEpic = (
    action$: Observable<FetchOldOrdersAction>,
    state$: StateObservable<RootState>,
): Observable<OldOrdersActionTypes> =>
    action$.pipe(
        ofType(FETCH_OLD_ORDERS),
        mergeMap((action) =>
            api
                .get('/order', {
                    page: action.payload.page.toString(10),
                    size: state$.value.oldOrders.pageSize.toString(10),
                    sort: 'orderTime,DESC',
                    filter: {
                        ...action.payload.filter,
                        status: [
                            'WAITING_SEND',
                            'SENT',
                            'DONE',
                            'EMAIL_FAIL',
                            'FILE_GENERATING_FAIL',
                            'FINALISE_FAIL',
                            'LEMONSOFT_VALIDATE_FAIL',
                            'LEMONSOFT_SEND_FAIL',
                        ],
                    },
                })
                .pipe(
                    // range=[X,Y]
                    map((res) => fetchOldOrdersSuccess(res.response, res.xhr.getResponseHeader('content-range') || '')),
                    catchError((error) => of(fetchOldOrdersFail(error))),
                ),
        ),
    );

export default oldOrders;
