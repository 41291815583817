import { useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorFlash from '../../components/Order/flash/ErrorFlash';
import LoadingIcon from '../../components/loading-icon';
import { getOrderPdf } from '../../lib/order';
import { Order } from '../../lib/order/types';
import { RootState } from '../../redux';

interface Props {
    order: Order;
}

const ListItemAction = ({ order }: Props) => {
    const oidcUser = useSelector((state: RootState) => state.oidc.user);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        if (!oidcUser?.access_token) return;

        setError(false);
        setLoading(true);
        (order.pdf ? Promise.resolve(order.pdf) : getOrderPdf(oidcUser.access_token, order.id))
            .then(({ url }) => window.open(url, '_blank'))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <div className='order-info__actions'>
                {['DONE', 'EMAIL_FAIL'].indexOf(order.status) !== -1 ? (
                    <button
                        className={`btn btn-link ${error ? 'btn-link--error' : ''}`}
                        disabled={loading}
                        onClick={handleClick}
                    >
                        {loading ? <LoadingIcon /> : 'Lataa PDF'}
                    </button>
                ) : (
                    'PDF ei vielä ladattavissa'
                )}
            </div>
            <ErrorFlash error='PDF:n lataaminen epäonnistui. Ole hyvä ja yritä myöhemmin uudelleen.' show={error} />
        </>
    );
};

export default ListItemAction;
