import _ from 'lodash';
import { User } from 'oidc-client';
import { Observable, from } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map, mergeMap } from 'rxjs/operators';

import userManager from './userManager';

const defaultUrlRoot = window.config.apiUrl;

const userToAuthToken = (user: User | null) =>
    user ? { Authorization: `${user.token_type} ${user.access_token}` } : {};

const defaultHeaders = () => ({
    'Content-type': 'Application/json',
});

const get = (
    url: string,
    queryParameters: { [k: string]: string | { [k: string]: string | string[] } } = {},
    headers: { [k: string]: string | undefined } = {},
): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) => {
            // Construct query parameter string
            const params = Object.keys(queryParameters);
            let parameterString = '';
            for (let i = 0; i < params.length; i++) {
                if (parameterString === '') {
                    parameterString += '?';
                } else {
                    parameterString += '&';
                }
                // Without this, non-objects get unnecessary "" around them
                if (typeof queryParameters[params[i]] === 'string') {
                    // Typescript wont recognize this as string without <string>
                    parameterString += `${params[i]}=${encodeURIComponent(queryParameters[params[i]] as string)}`;
                } else {
                    parameterString += `${params[i]}=${encodeURIComponent(JSON.stringify(queryParameters[params[i]]))}`;
                }
            }

            return ajax({
                method: 'GET',
                url: `${defaultUrlRoot}${url}${parameterString}`,
                headers: _.assign({}, defaultHeaders(), authHeaders, headers),
            });
        }),
    );

const post = (
    url: string,
    body: Record<string, unknown>,
    headers: { [k: string]: string } = {},
): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax({
                method: 'POST',
                url: `${defaultUrlRoot}${url}`,
                body: JSON.stringify(body),
                headers: _.assign({}, defaultHeaders(), authHeaders, headers),
            }),
        ),
    );

const put = (
    url: string,
    body: Record<string, unknown>,
    headers: { [k: string]: string } = {},
): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax({
                method: 'PUT',
                url: `${defaultUrlRoot}${url}`,
                body: JSON.stringify(body),
                headers: _.assign({}, defaultHeaders(), authHeaders, headers),
            }),
        ),
    );

const patch = (
    url: string,
    body: Record<string, unknown>,
    headers: { [k: string]: string } = {},
): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax({
                method: 'PATCH',
                url: `${defaultUrlRoot}${url}`,
                body: JSON.stringify(body),
                headers: _.assign({}, defaultHeaders(), authHeaders, headers),
            }),
        ),
    );

// delete not allowed as variable name
const apiDelete = (url: string, headers: { [k: string]: string } = {}): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax({
                method: 'DELETE',
                url: `${defaultUrlRoot}${url}`,
                headers: _.assign({}, defaultHeaders(), authHeaders, headers),
            }),
        ),
    );

export const keepAlive = async (
    method: string,
    url: string,
    body: Record<string, unknown>,
    headers: { [k: string]: string } = {},
) => {
    const user = await userManager.getUser();
    const authHeaders = userToAuthToken(user);
    return fetch(`${defaultUrlRoot}${url}`, {
        method,
        keepalive: true,
        body: JSON.stringify(body),
        headers: _.assign({}, defaultHeaders(), authHeaders, headers),
    });
};

const blob = (url: string, headers: { [k: string]: string } = {}): Observable<AjaxResponse> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax({
                method: 'GET',
                url: `${defaultUrlRoot}${url}`,
                headers: _.assign({}, defaultHeaders(), authHeaders, headers),
                responseType: 'arraybuffer',
            }),
        ),
    );

export const api = {
    get,
    post,
    put,
    patch,
    delete: apiDelete,
    keepAlive,
    blob,
};

export const parseContentRange = (header: string | null): number => (header ? parseInt(header.split('/')[1], 10) : 0);

export const parseContentDispositionFilename = (header: string | null): string | undefined => {
    if (!header) return;
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(header);
    if (matches != null && matches[1]) return matches[1].replace(/['"]/g, '');
};
